import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, FormCheck, FormControl, FormSelect, Row, Stack, Table } from 'react-bootstrap'
import {
    BulkCheckboxControl,
    DatatableWrapper,
    TableBody,
    TableHeader,
} from "react-bs-datatable";
import SparkApi from '../api/SparkApi';
import { toast } from 'react-toastify';
import * as constants from "../constants/CONSTANT";
import jwt_decode from "jwt-decode";
import moment from 'moment-timezone';
import UnderwriterBulkChangeModel from './UnderwriterBulkChangeModel';
import Confirm from './Confirm';

export default function UnderwritingJourney({ leadId, rows, setRows, fieldValidations, setFieldValidations, changedRows, setChangedRows, lead }) {

    // Initial values for Underwriter journey
    const initialValues = {
        "product_quote": `QTN${Math.floor(Math.random() * 100000)}`,
        "product_code": "",
        "product_name": "",
        "under_writing_status": "",
        "risk_inspection": "",
        "lead_id": `${leadId}`,
        "remark": "",
        "policy_expiry_date": null,
        "inscompanyname": "",
        "premiumquote": "",
        "converted": false,
        "underwriter_manager_id": null,
    }

    // const [header, setHeader] = useState([]);
    const [products, setProducts] = useState([]);
    const [productCodes, setProductCodes] = useState([]);

    // Here we manage the states for userInfo
    const [userInfo, setUserInfo] = useState(null);

    // Here we manage the states for underwriter managers
    const [underwriters, setUnderwriters] = useState([]);

    // Here we manage the states for show check box
    const [showCheckBox, setShowCheckBox] = useState(false);

    // States for UnderwriterBulkChangeModel
    const [UWModalShow, setUWModalShow] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);

    // States fro delete functionality
    const [showConfirmModel, setShowConfirmModel] = useState(false);
    // Ref for data table
    const UWDataTable = useRef(null);
    // Here is the functionality of Bulk Change
    const handleBulkChange = () => {
        if (UWDataTable?.current?.children && UWDataTable?.current?.children?.length > 0) {
            const checkboxes = [...UWDataTable?.current?.children[1]?.querySelectorAll(".UWCheckBox")];
            let filteredId = checkboxes.map((checkbox) => {
                if (checkbox?.children && checkbox?.children?.length > 0 && checkbox?.children[0].checked) {
                    if (checkbox?.children[0].value) {
                        return checkbox?.children[0].value
                    } else {

                    }
                }
            });
            console.log("filteredId", filteredId);
            filteredId = filteredId?.filter(item => item);
            if (filteredId?.length > 0) {
                setSelectedIds(filteredId);
                setUWModalShow(true);
            }
            else {
                toast.error("Please select a row");
            }
        }
    }
    //here we Fetch the users
    const fetchAllUser = async () => {
        // fetch the user according to role
        // Added role HEAD_UNDERWRITING 18 Sept 2024 @ali
        if (userInfo.userrole === 'HEAD_UNDERWRITING') {
            const fetchUser = await SparkApi.fetchUsersByManagerId(userInfo);
            // console.log('fetchUser===>', fetchUser)
            setUnderwriters(fetchUser);
        }
        else if (userInfo.userrole === 'ADMIN_UNDERWRITING') {
            const fetchUser = await SparkApi.fetchUsersByManagerId(userInfo);
            // console.log('fetchUser===>', fetchUser)
            setUnderwriters(fetchUser)
        } else if (userInfo.userrole === 'RELATIONSHIP_MANAGER') {
            const fetchUser = await SparkApi.fetchUsers();
            // console.log('fetchUser===>1', fetchUser)
            let filterdata = fetchUser.filter((value, index, array) => value.userrole === 'ADMIN_UNDERWRITING' || value.userrole === 'UNDERWRITER_MANAGER');
            // console.log('filterdata========', filterdata)
            setUnderwriters(filterdata)
        } else if (userInfo.userrole === 'UNDERWRITER_MANAGER') {
            const fetchUser = await SparkApi.fetchUsersByManagerId(userInfo);
            console.log('fetchUser===>', fetchUser)
            setUnderwriters(fetchUser)
        }
        // If Users not related to Underwriters (i.e Head sales, admin sales and relationship manager's)
        else {
            const fetchUser = await SparkApi.fetchUsers();
            // console.log('fetchUser', fetchUser)
            let filterUsers = null;
            // Set users in Underwriter Manager dropdown list according to role 19 Sept 2024 @ali 
            if (userInfo.userrole === 'SUPER_ADMIN')
                filterUsers = fetchUser.filter((value, index, array) => value.userrole === 'HEAD_UNDERWRITING' || value.userrole === 'ADMIN_UNDERWRITING' || value.userrole === 'UNDERWRITER_MANAGER');
            else if (userInfo.userrole === 'HEAD_SALES')
                filterUsers = fetchUser.filter((value, index, array) => value.userrole === 'HEAD_UNDERWRITING' || value.userrole === 'ADMIN_UNDERWRITING' || value.userrole === 'UNDERWRITER_MANAGER');
            // for admin sales and relationship manager's
            else
                filterUsers = fetchUser.filter((value, index, array) => value.userrole === 'ADMIN_UNDERWRITING' || value.userrole === 'UNDERWRITER_MANAGER');
            setUnderwriters(filterUsers || []);

        }
    }



    // Fetch the products for Underwriting fields
    async function fetchProducts() {
        const products = await SparkApi.fetchProducts();

        setProducts(products);

        // console.log("products", products);

        setProductCodes([...new Set(products.map((product) => product.productcode))]);

        // console.log("productCodes",[...new Set(products.map((product)=>product.productcode))]); 
    }

    // Fetch the data for Underwriting journey
    async function fetchUWRecords() {
        try {
            const result = await SparkApi.fetchUWRecordsByLeadId(leadId);

            // throw  new Error("Test error");

            if (!Array.isArray(result)) throw new Error(result.errors);

            if (result.length > 0) {
                console.log("Underwriting Records : ", result);

                setRows(result);

                // We does not set headers for some issues ( we can't access the updated rows when we set headers here )

            }
        } catch (error) {
            console.log("Error in Underwriting Journey : ", error.message);
            // New Change : to hide no records found error
            if (error.message != `Underwriting records not found`) {
                toast.error(error.message, {
                    position: 'top-center',
                });
            }
        }
    }

    // Here we set the UserInfo by Token
    useEffect(() => {
        const token = localStorage.getItem("token")
        if (token) {
            setUserInfo(jwt_decode(token));
        }
    }, []);

    useEffect(() => {
        // console.log("userInfo", userInfo)
        if (userInfo) {
            fetchAllUser();
        }
    }, [userInfo]);

    useEffect(() => {
        console.log("underwriters", underwriters)

    }, [underwriters]);

    // To Fetch the products and product code
    useEffect(() => {
        // console.log("PRODUCTS==============================================");
        fetchProducts();
    }, []);

    // Here we fetch the UW records
    useEffect(() => {
        // console.log("first==============================================");
        // if leadId present
        if (leadId) {
            fetchUWRecords();
        }
        // Otherwise
        else {
            // fetchUWRecords();
            setRows([{ ...initialValues }])
        }
    }, []);

    // Here we handle the checkboxes 
    const handleCheckBox = (row, e) => {
        // const updatedRows = [...rows];
        // updatedRows.forEach((row) => {
        //     if (row.id == e.target.value) {
        //     }
        // });
        console.log("Handle CheckBoxes", e.target, e.target.value, e.target.checked);
    }

    // Here we handle the Select All checkbox 
    const handleSelectAllCheckBox = (row, e) => {

    }

    // Here is the row change handler for handle the records of row on change event 
    const handleRowChange = (id, productQuote, fieldName, value) => {
        // console.log("handleRowChange", id, productQuote, fieldName, value);
        // console.log("rows in handleRowChange", rows);
        const updatedRows = [...rows];

        // IF id present in the row so change the value according to id
        if (id) {
            console.log("Change by ID");
            updatedRows.forEach((row) => {
                if (row.id == id) {

                    // New Changes :  fixed the Underwriter manager issue(UUID not accept "" so send null instead of "") 12 Nov
                    // (Note : send null to all fields that contain empty("") values)
                    row[fieldName] = value || null;
                    
                    // When field name is product code then mke empty it's depended field  product name
                    if (fieldName == "product_code")
                        row.product_name = "";
                }
            });


            setChangedRows([...changedRows, { id }]);

        }
        //Otherwise change the value according to product_quote
        else {
            console.log("Change by Quote");
            updatedRows.forEach((row) => {
                if (row?.product_quote == productQuote) {

                    // New Changes :  fixed the Underwriter manager issue(UUID not accept "" so send null instead of "")  12 Nov 2024 
                    // (Note : send null to all fields that contain empty("") values)
                    row[fieldName] = value || null;
                    // When field name is product code then mke empty it's depended field  product name
                    if (fieldName == "product_code")
                        row.product_name = "";
                }
            })
            setChangedRows([...changedRows, { 'product_quote': productQuote }]);
        }

        setRows(updatedRows);


        const updatedFieldValidations = { ...fieldValidations };
        // Check if Under Writing Status is Data Insufficient to Quote
        if (fieldName === 'under_writing_status' && value === 'Data Insufficient to Quote' || value === 'Quote Sent to RM') {
            updatedFieldValidations.remark = true;
        } else if (fieldName === 'under_writing_status' && value !== 'Data Insufficient to Quote' && value !== 'Quote Sent to RM') {
            updatedFieldValidations.remark = false;
        }

        if (fieldName === 'under_writing_status' && value === 'Quote Sent to RM') {
            updatedFieldValidations.insuranceData = true;
        } else {
            updatedFieldValidations.insuranceData = false;
        }
        setFieldValidations(updatedFieldValidations);



        if (fieldName === 'under_writing_status' && value === 'Data Insufficient to Quote') {
            lead.leadstatus = 'Query Raised by Underwriter'
        } else if (fieldName === 'under_writing_status' && value === 'Quote Sent to RM') {
            lead.leadstatus = 'Quote Received'

        } else if (fieldName === 'under_writing_status' && value === 'Risk Inspection required by Insurance Company') {
            lead.leadstatus = 'Query Raised by Underwriter'
        }
    }

    // Here is add row functionality
    const addRow = () => {
        // console.log("rows in Add row : ", rows);
        setRows([...rows, { ...initialValues, product_quote: `QTN${Math.floor(Math.random() * 100000)}` }])
    }
    // Here is delete row functionality
    const deleteRow = async (id, product_quote) => {


        const updatedRows = [...rows];
        let filteredRow = [];
        // if id present then delete by id
        if (id) {
            filteredRow = updatedRows.filter((row) => (row?.id != id));
            try {
                const result = await SparkApi.deleteUWRecord(id);
                console.log("Result On delete UW : ", result);
            } catch (error) {
                console.log("Error on Delete UW : ", error);
            }

        }
        // otherwise delete by product quote
        else {
            filteredRow = updatedRows.filter((row) => (row.product_quote != product_quote));
        }

        setRows(filteredRow);

    };


    return (
        <>
            {/* <ToastContainer/> */}
            <Col lg={12} className="section-header">
                <Row className="justify-content-center align-items-center">
                    <Col
                    // lg={7} 
                    >
                        Underwriting Journey Information
                    </Col>

                    <Col lg={5}>
                        <Stack direction="horizontal" className="gap-2" style={{ float: 'right' }}>
                            {/* {userInfo?.userrole === 'ADMIN_UNDERWRITING' || userInfo?.userrole === 'UNDERWRITER_MANAGER' ?
                                null
                                :
                                !"isCheckboxesAreVisible"
                                    ? 
                                    <Button
                                        variant="light"
                                    // onClick={StatEditUW}
                                    // Removed disabled functionality as per client's instructions 8 Oct 2024
                                    // disabled={
                                    //   (lead.id && (userInfo.userrole === 'HEAD_SALES' || userInfo.userrole === 'ADMIN_SALES' || userInfo.userrole === 'RELATIONSHIP_MANAGER'))
                                    // }
                                    >
                                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    </Button>
                                    :
                                    <>
                                        <Button
                                        //   onClick={handleBulkChange}
                                        >
                                            Change
                                        </Button>

                                        <Button
                                            variant="danger"
                                        //   onClick={cancelChangeUW}
                                        >
                                            Cancel
                                        </Button>
                                    </>
                            } */}
                            {/* {userInfo?.userrole === 'ADMIN_UNDERWRITING' || userInfo?.userrole === 'UNDERWRITER_MANAGER'
                                ?
                                null
                                : */}
                            {!showCheckBox ?
                                <Button
                                    variant="light"
                                    onClick={() => {
                                        setShowCheckBox(!showCheckBox)
                                    }}
                                >
                                    <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                </Button>
                                :
                                <>
                                    <Button
                                        onClick={handleBulkChange}
                                    >
                                        Change
                                    </Button>

                                    <Button
                                        variant="danger"
                                        onClick={() => { setShowCheckBox(false) }}
                                    >
                                        Cancel
                                    </Button>
                                </>}
                            <Button
                                onClick={addRow}
                            // Removed disabled functionality as per client's instructions 2 Oct 2024
                            // disabled={isCheckboxesAreVisible ||
                            //   (lead.id && (userInfo.userrole === 'HEAD_SALES' || userInfo.userrole === 'ADMIN_SALES' || userInfo.userrole === 'RELATIONSHIP_MANAGER'))
                            // }
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </Button>
                            {/* } */}
                        </Stack>
                    </Col>
                </Row>
            </Col>
            <Col lg={12} className="overflow-auto p-0 m-0 ">
                <DatatableWrapper

                    body={rows}
                    headers={[
                        {
                            title: "id",
                            prop: "id",
                            // headerCell:()=>{
                            //     return <FormCheck
                            //                 name="selectAll"
                            //             />
                            // },
                            // cell: (row) => {
                            //     return <FormCheck
                            //         name="id"
                            //         value={row?.id}
                            //         checked={row?.isChecked}
                            //         onChange={(e)=>(handleCheckBox(row,e))}
                            //     />
                            // },
                            checkbox: {
                                idProp: "product_quote",
                                className: "UWCheckBox"
                            },
                            thProps: {
                                className: `${showCheckBox ? '' : 'd-none'}`
                            },
                            cellProps: {
                                className: `${showCheckBox ? '' : 'd-none'}`
                            }
                        },
                        {
                            title: "product quote",
                            prop: "product_quote",
                            cell: (row) => {
                                return <FormControl
                                    type='text'
                                    name="product_quote"
                                    value={row?.product_quote || ""}
                                    readOnly
                                />
                            },
                            thProps: { className: "d-none" },
                            cellProps: { className: "d-none" }
                        },
                        {
                            title: "product code",
                            prop: "product_code",
                            cell: (row) => {
                                return <FormSelect
                                    name="product_code"
                                    required={fieldValidations["product_code"]}
                                    value={row?.product_code || ""}
                                    onChange={(e) => { handleRowChange(row.id, row?.product_quote, e.target.name, e.target.value) }}

                                    // Add disabled functionality as per client's instructions 19 DEC 2024
                                    disabled={
                                        ((userInfo.userrole === 'HEAD_UNDERWRITING') || (userInfo.userrole === 'ADMIN_UNDERWRITING') || (userInfo.userrole === 'UNDERWRITER_MANAGER'))
                                    }
                                >
                                    <option value={""} key={null}>--Select--</option>
                                    {productCodes.map((productCode, index) => <option value={productCode || ""} key={index} >{productCode}</option>)}
                                </FormSelect>
                            }
                        },
                        {
                            title: "product name",
                            prop: "product_name",
                            cell: (row) => {

                                // console.log("In product_name dropdown :row",row);

                                // Here we created the logic : set product_name in the dropdown according to product name
                                const productNames = products?.filter((product) => product.productcode == row.product_code)?.map((product) => product.productname)
                                return <FormSelect
                                    name="product_name"
                                    required={fieldValidations["product_name"]}
                                    value={row?.product_name || ""}
                                    onChange={(e) => { handleRowChange(row.id, row?.product_quote, e.target.name, e.target.value) }}
                                    // Add disabled functionality as per client's instructions 19 DEC 2024
                                    disabled={
                                        ((userInfo.userrole === 'HEAD_UNDERWRITING') || (userInfo.userrole === 'ADMIN_UNDERWRITING') || (userInfo.userrole === 'UNDERWRITER_MANAGER'))
                                    }
                                >
                                    <option value={""} key={null}>--Select--</option>
                                    {productNames.map((productName, index) => <option value={productName || ""} key={index}>{productName}</option>)}

                                </FormSelect>
                            }
                        },
                        {
                            title: "underwriting status",
                            prop: "under_writing_status",
                            cell: (row) => {
                                // console.log("Rows in header : ",rows)
                                return <FormSelect
                                    name="under_writing_status"
                                    required={fieldValidations["under_writing_status"]}
                                    value={row?.under_writing_status || ""}
                                    onChange={(e) => { handleRowChange(row.id, row?.product_quote, e.target.name, e.target.value) }}
                                    // Add disabled functionality as per client's instructions 19 DEC 2024
                                    disabled={
                                        (userInfo.userrole === 'HEAD_SALES' || userInfo.userrole === 'ADMIN_SALES' || userInfo.userrole === 'RELATIONSHIP_MANAGER')
                                    }
                                >
                                    <option value={""} key={null} >--Select--</option>
                                    {constants.UNDER_WRITER_STATUS.map((status, index) => (
                                        <option key={index} value={status.value || ""}>{status.value}</option>
                                    ))}
                                </FormSelect>
                            }
                        },
                        {
                            title: "risk inspection",
                            prop: "risk_inspection",
                            cell: (row) => {
                                return <FormSelect
                                    name="risk_inspection"
                                    required={fieldValidations["risk_inspection"]}
                                    value={row?.risk_inspection || ""}
                                    onChange={(e) => { handleRowChange(row.id, row?.product_quote, e.target.name, e.target.value) }}
                                    // Add disabled functionality as per client's instructions 19 DEC 2024
                                    disabled={
                                        (userInfo.userrole === 'HEAD_SALES' || userInfo.userrole === 'ADMIN_SALES' || userInfo.userrole === 'RELATIONSHIP_MANAGER')
                                    }
                                >
                                    <option value={""}>--Select--</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </FormSelect>
                            }
                        },
                        {
                            title: "underwriter manager",
                            prop: "underwriter_manager_id",
                            cell: (row) => {
                                return <FormSelect
                                    name="underwriter_manager_id"
                                    required={fieldValidations["underwriter_manager_id"]}
                                    value={row?.underwriter_manager_id || ""}
                                    onChange={(e) => { handleRowChange(row.id, row?.product_quote, e.target.name, e.target.value) }}
                                >
                                    <option value={""} key={null}>--Select--</option>
                                    {underwriters?.map((underwriter, index) => <option value={underwriter?.id || ""} key={index}>{underwriter.username}</option>)}
                                </FormSelect>
                            }
                        },
                        {
                            title: "remark",
                            prop: "remark",
                            cell: (row) => {
                                return <FormControl
                                    as="textarea"
                                    rows={1}
                                    style={{ width: "10em" }}
                                    name="remark"
                                    required={fieldValidations["remark"]}
                                    value={row?.remark || ""}
                                    onChange={(e) => { handleRowChange(row.id, row?.product_quote, e.target.name, e.target.value) }}
                                    // Add disabled functionality as per client's instructions 19 DEC 2024
                                    disabled={
                                        (userInfo.userrole === 'HEAD_SALES' || userInfo.userrole === 'ADMIN_SALES' || userInfo.userrole === 'RELATIONSHIP_MANAGER')
                                    }
                                />
                            }
                        },
                        {
                            title: "policy expiry date",
                            prop: "policy_expiry_date",
                            cell: (row) => {
                                // console.log("DATE",row?.policy_expiry_date ? moment(row?.policy_expiry_date).tz("Asia/Kolkata").format("YYYY-MM-DD"): null)
                                return <FormControl
                                    type="date"
                                    name="policy_expiry_date"
                                    required={fieldValidations["policy_expiry_date"]}
                                    value={row?.policy_expiry_date ? moment(row?.policy_expiry_date).tz("Asia/Kolkata").format("YYYY-MM-DD") : null}
                                    onChange={(e) => { handleRowChange(row.id, row?.product_quote, e.target.name, e.target.value) }}
                                />
                            }
                        },
                        {
                            title: "converted",
                            prop: "converted",
                            cell: (row) => {
                                return <FormCheck
                                    type="checkbox"
                                    name="converted"
                                    checked={row?.converted}
                                    onChange={(e) => { handleRowChange(row.id, row?.product_quote, e.target.name, e.target.checked) }}
                                />
                            },
                            alignment: {
                                /** Sets the horizontal alignment of the cell. */
                                horizontal: 'center'
                            }
                        },
                        {
                            title: "Action", prop: "", cell: (row) => {
                                // console.log("row",row)
                                return <Button
                                    variant="danger"
                                    onClick={() => deleteRow(row?.id, row.product_quote)}
                                // Removed disabled functionality as per client's instructions 8 Oct 2024
                                // disabled={
                                //   (lead.id && (userInfo.userrole === 'HEAD_SALES' || userInfo.userrole === 'ADMIN_SALES' || userInfo.userrole === 'RELATIONSHIP_MANAGER'))
                                // }

                                    // New Change :  Make disable the delete button for UNDERWRITER_MANAGER as per client need 28 OCT 2024 
                                    disabled={(userInfo.userrole == 'UNDERWRITER_MANAGER')}
                                >
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </Button>
                            }
                        },
                    ]}

                >
                    {showCheckBox &&
                        <Row>
                            <Col lg={12} className='px-4 py-1' >
                                <BulkCheckboxControl />
                            </Col>
                        </Row>
                    }
                    <Table className="data-table" responsive="sm" ref={UWDataTable}>
                        <TableHeader />
                        <TableBody />
                    </Table>
                </DatatableWrapper>
            </Col>

            <UnderwriterBulkChangeModel
                UWModalShow={UWModalShow}
                setUWModalShow={setUWModalShow}
                selectedIds={selectedIds}
                underwriters={underwriters}
                rows={rows}
                showCheckBox={showCheckBox}
                setShowCheckBox={setShowCheckBox}
                setChangedRows={setChangedRows}
                changedRows={changedRows}
                userInfo={userInfo}
                leadId={leadId}
            />


            <Confirm
                show={showConfirmModel}
                onHide={() => setShowConfirmModel(false)}
                // deleteUnderwriter={deleteUnderwriter}
                title="Confirm delete?"
                message="You are going to delete the record. Are you sure?"
                table="under_writing"
            />
        </>
    )
}


