import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    DatatableWrapper,
    Pagination,
    TableBody,
    TableHeader,
} from 'react-bs-datatable';
import SparkApi from "../api/SparkApi";
import moment from "moment-timezone";
import { Row, Col, Table, Form, Button, Container, Stack } from "react-bootstrap";
import Select from "react-select";
import csvDownload from 'json-to-csv-export'
import jwt_decode from "jwt-decode";

const ViewTaskReports = (props) => {
    // console.log('props', props);
    const location = useLocation();
    const [state, setState] = useState(location.state ? location.state : {});
    const [reports, setReports] = useState([]);
    const [lead, setlead] = useState([]);
    const [leadActivityReport, setleadActivityReport] = useState([]);

    const [tempdata, settempdata] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [status, setStatus] = useState('');
    const [ownerRole, setOwnerRole] = useState('');
    const [option, setoption] = useState();
    const [optionUsers, setOptionUsers] = useState([]);

    // New Changes :  added the state for new filter 11 Nov 2024
    const [filterDate, setFilterDate] = useState("");

    const [userInfo, setUserInfo] = useState(
        jwt_decode(localStorage.getItem("token"))
    );
    const fetchAllUsers = async () => {
        const result2 = await SparkApi.fetchUsers();
        // console.log('result2', result2)
        if (result2) {
            let ar = [];
            // Fixed Filter : in the user drop down show all users when the report is not equal to 'meetingreport' 11 NOV 2024
            if (state.apiname === 'meetingreport') {

                if (userInfo.userrole === 'ADMIN_SALES') {
                    result2?.filter((data) => data.userrole === "ADMIN_SALES" && data.id === userInfo.id).map((item) => {
                        var obj = {};
                        obj.value = item.id;
                        obj.label = item.username;
                        ar.push(obj);
                    });

                } else {
                    result2?.filter((data) => data.userrole === "ADMIN_SALES").map((item) => {
                        var obj = {};
                        obj.value = item.id;
                        obj.label = item.username;
                        ar.push(obj);
                    });
                }
                setOptionUsers(ar);
            }
            else {
                // console.log("LIST OF USERS",result2);
                const users = result2?.map((item) => {
                    var obj = {};
                    obj.value = item.id;
                    obj.label = item.username;
                    return obj;
                })
                setOptionUsers(users || []);
            }
        } else {
            setOptionUsers([]);
        }

    }

    let tempLabels;
    useEffect(() => {

        fetchAllReportHistory()
    }, []);

    const fetchAllReportHistory = async () => {
        if (state.apiname === 'meetingreport') {
            let result = await SparkApi.fetchTaksHistory(state.apiname)
            try {
                if (result && result.length > 0) {
                    let filterdata = result;
                    // console.log('filterdata', filterdata);
                    tempLabels = Object.keys(filterdata[0]);

                    setReports(filterdata);
                    settempdata(filterdata);
                    const headers = Object.keys(filterdata[0])
                        .filter(key => key !== 'managerid' && key !== 'ownerid' && key !== 'Products')
                        .map(key => ({ prop: key, title: key }));
                    // console.log('headers', headers)
                    setHeaders(headers);
                } else {
                    setleadActivityReport([]);
                    setReports([]);
                    window.alert('No Data available');
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);

            }
        }

        else if (state.apiname === 'leadsummaryreport') {
            try {
                // Fixed Report : fied the report and make it according to new underwriter 11 Nov 2024 
                let result = await SparkApi.fetchTaksHistory(state.apiname);
                console.log('leadsummaryreport : result', result)
                // let filterdata = [];
                // if (result && result.length > 0) {
                //     for (let lead of result) {
                //         let entryOrigin = {};
                //         for (let productArray of lead.Products) {
                //             if(!Array.isArray(productArray)) break;
                //             for (let product of productArray) {
                //                 // Parse "Under Writer Manager" only if it's a string
                //                 let underWriterManager = product["Under Writer Manager"];
                //                 let underWriterManagerUsername = '';

                //                 // Check if "Under Writer Manager" is a string and parse it
                //                 if (typeof underWriterManager === 'string') {
                //                     try {
                //                         let parsedManager = JSON.parse(underWriterManager);
                //                         underWriterManagerUsername = parsedManager.username || '';
                //                     } catch (error) {
                //                         console.error('Error parsing JSON:', error);
                //                     }
                //                 }

                //                 // Create a new entry for each product along with lead information
                //                 let entry = {

                //                     "Customer Name": lead["Customer Name"],
                //                     "Company Name": lead.Company,
                //                     "Company Group": lead["Company Group"],
                //                     "Relationship Manager Name": lead["Relationship Manager Name"],
                //                     "Manager Name": lead["Manager Name"],
                //                     "Address": lead.Address,
                //                     "Phone Number": lead.Phone,
                //                     "Source": lead.Source,
                //                     "Type of Business": lead["Type of Business"],
                //                     "Lead Contact Date": lead["Lead Contact Date"],
                //                     "Lead Creation Date": lead["Lead Creation Date"],
                //                     "QRN Number": product["Product Quote"],
                //                     "Type of Policy": product["Product Code"] + ' - ' + product["Product Name"],
                //                     "Existing Policy Expiry Date": product["Policy Expiry Date"],
                //                     "Underwriter Assigned": underWriterManagerUsername,
                //                     //"Remark": product.Remark,
                //                     //"Policy Expiry Date": product["Policy Expiry Date"],
                //                     "RFQ Date": lead["RFQ Date"],
                //                     "Quote Sent Date": lead["Quote Sent Date"],
                //                     "Lead Remarks": lead["Lead Remarks"],
                //                     "Lead Status": lead["Lead Status"],
                //                     "Case Lost Reason": lead["Case Lost Reason"],

                //                     "Number of Meetings": lead["numberofmeetings"],
                //                     "Number of Calls": lead["numberofcalls"],
                //                     "RAA done?": lead["RAA Completed"],

                //                     "Last Modified Date": lead["Last Modified Date"]
                //                 };
                //                 entryOrigin = entry;
                //                 // Output or process the entry as needed
                //                 // console.log('entry', entry);

                //                 // Push the entry into the filterdata array

                //             }

                //         }
                //         filterdata.push(entryOrigin);
                //     }

                // }

                if (result && result.length > 0) {
                    setlead(result); // Set the state with filterdata
                    const headers = Object.keys(result[0])
                        .filter(key => key !== 'managerid' && key !== 'ownerid' && key !== 'Products')
                        .map(key => ({ prop: key, title: key }));

                    // console.log('headers', headers);
                    setHeaders(headers);

                    // let filterdatanew = result.map((value) => {
                    //     return {
                    //         ...value,
                    //         'Quote Sent Date': value['Quote Sent Date'] ? moment(value['Quote Sent Date']).format('DD-MM-YYYY') : "",
                    //         'Lead Creation Date': value['Lead Creation Date'] ? moment(value['Lead Creation Date']).format('DD-MM-YYYY') : "",
                    //         'Existing Policy Expiry Date': value['Existing Policy Expiry Date'] ? moment(value['Existing Policy Expiry Date']).format('DD-MM-YYYY') : "",
                    //         'RFQ Date': value['RFQ Date'] ? moment(value['RFQ Date']).format('DD-MM-YYYY') : "",
                    //         'Last Modified Date': moment(value['Last Modified Date']).format('DD-MM-YYYY')
                    //     };
                    // });
                    // console.log('filterdatanew', filterdatanew)
                    setReports(result);


                } else {
                    setlead([]);
                    setReports([]);
                    window.alert('No Data available');
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
                // Handle error here, such as displaying an error message to the user
            }
        }
        // New Change : added a new report (underwriter_report) in the task activity report 12 Nov 2024
        else if (state.apiname === 'underwriter_report') {
            let result = await SparkApi.fetchTaksHistory(state.apiname)
            try {
                if (result && result.length > 0) {


                    // tempLabels = Object.keys(filterdata[0]);

                    setReports(result);
                    // settempdata(result);
                    const headers = Object.keys(result[0])?.map(key => ({ prop: key, title: key }));
                    //     .filter(key => key !== 'managerid' && key !== 'ownerid' && key !== 'Products')
                    //     .map(key => ({ prop: key, title: key }));
                    // // console.log('headers', headers)
                    setHeaders(headers);
                } else {
                    setleadActivityReport([]);
                    setReports([]);
                    window.alert('No Data available');
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);

            }
        }
        else {
            //console.log('yrs')
            try {
                let result = await SparkApi.fetchTaksHistory(state.apiname);
                // console.log('result activity', result)

                if (result && result.length > 0) {


                    setReports(result);
                    setleadActivityReport(result);
                    const headers = Object.keys(result[0])
                        .filter(key => key !== 'managerid' && key !== 'ownerid' && key !== 'Products')
                        .map(key => ({ prop: key, title: key }));
                    // console.log('headers', headers)
                    setHeaders(headers);
                } else {
                    setleadActivityReport([]);
                    setReports([])
                    window.alert('No Data available');
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
                // Handle error here, such as displaying an error message to the user
            }
        }

    }
    const fetchAllData = async () => {
        //console.log('sfddsgg')
        if (state.apiname === 'meetingreport') {
            let result = await SparkApi.fetchTaksByOwnerAll(filterDate, moment(fromDate).format("DD-MM-YYYY"), moment(toDate).format("DD-MM-YYYY"), option?.value, state.apiname)
            try {
                if (result && result.length > 0) {
                    let filterdata = result;
                    //console.log('filterdata', filterdata);
                    tempLabels = Object.keys(filterdata[0]);

                    setReports(filterdata);
                    settempdata(filterdata);
                    const headers = Object.keys(filterdata[0])
                        .filter(key => key !== 'managerid' && key !== 'ownerid' && key !== 'Products')
                        .map(key => ({ prop: key, title: key }));
                    // console.log('headers', headers)
                    setHeaders(headers);
                } else {
                    // Fixed issue :  when data not found make table empty
                    setReports([]);
                    settempdata([]);
                    setHeaders([]);
                    window.alert('No Data available');
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        }

        else if (state.apiname === 'leadsummaryreport') {
            try {
                let result = await SparkApi.fetchTaksByOwnerAll(
                    filterDate,
                    moment(fromDate).format("DD-MM-YYYY"),
                    moment(toDate).format("DD-MM-YYYY"),
                    option?.value,
                    state.apiname
                );
                // Fixed Issue : fixed the report and make it according to new Under writing table

                //console.log('result', result)
                // let filterdata = [];
                // if (result && result.length > 0) {
                //     for (let lead of result) {
                //         let entryOrigin;
                //         for (let productArray of lead.Products) {
                //             for (let product of productArray) {
                //                 // Parse "Under Writer Manager" only if it's a string
                //                 let underWriterManager = product["Under Writer Manager"];
                //                 let underWriterManagerUsername = '';

                //                 // Check if "Under Writer Manager" is a string and parse it
                //                 if (typeof underWriterManager === 'string') {
                //                     try {
                //                         let parsedManager = JSON.parse(underWriterManager);
                //                         underWriterManagerUsername = parsedManager.username || '';
                //                     } catch (error) {
                //                         console.error('Error parsing JSON:', error);
                //                     }
                //                 }

                //                 // Create a new entry for each product along with lead information
                //                 let entry = {

                //                     "Customer Name": lead["Customer Name"] ? lead["Customer Name"] : "",
                //                     "Company Name": lead.Company ? lead.Company : "",
                //                     "Company Group": lead["Company Group"],
                //                     "Relationship Manager Name": lead["Relationship Manager Name"],
                //                     "Manager Name": lead["Manager Name"],
                //                     "Address": lead.Address,
                //                     "Phone Number": lead.Phone,
                //                     "Source": lead.Source,
                //                     "Type of Business": lead["Type of Business"],
                //                     "Lead Contact Date": lead["Lead Contact Date"],
                //                     "Lead Creation Date": lead["Lead Creation Date"],
                //                     "QRN Number": product["Product Quote"],
                //                     "Type of Policy": product["Product Code"] + ' - ' + product["Product Name"],
                //                     "Existing Policy Expiry Date": product["Policy Expiry Date"],
                //                     "Underwriter Assigned": underWriterManagerUsername,
                //                     //"Remark": product.Remark,
                //                     //"Policy Expiry Date": product["Policy Expiry Date"],
                //                     "RFQ Date": lead["RFQ Date"],
                //                     "Quote Sent Date": lead["Quote Sent Date"],
                //                     "Lead Remarks": lead["Lead Remarks"],
                //                     "Lead Status": lead["Lead Status"],
                //                     "Case Lost Reason": lead["Case Lost Reason"],

                //                     "Number of Meetings": lead["numberofmeetings"],
                //                     "Number of Calls": lead["numberofcalls"],
                //                     "RAA done?": lead["RAA Completed"],

                //                     "Last Modified Date": lead["Last Modified Date"]
                //                 };
                //                 entryOrigin = entry;
                //                 // Output or process the entry as needed
                //                 // console.log('entry', entry);

                //                 // Push the entry into the filterdata array

                //             }

                //         }
                //         filterdata.push(entryOrigin);
                //     }
                // }

                if (result && result.length > 0) {
                    // console.log('filterdata',filterdata)
                    setlead(result); // Set the state with filterdata
                    const headers = Object.keys(result[0])
                        .filter(key => key !== 'managerid' && key !== 'ownerid' && key !== 'Products')
                        .map(key => ({ prop: key, title: key }));

                    //console.log('headers', headers);
                    setHeaders(headers);

                    // let filterdatanew = filterdata.map((value) => {
                    //     return {
                    //         ...value,
                    //         'Quote Sent Date': value && value['Quote Sent Date'] && value['Quote Sent Date'] !== null && value['Quote Sent Date'] !== undefined ? moment(value['Quote Sent Date']).format('DD-MM-YYYY') : "",
                    //         'Lead Creation Date': value && value['Lead Creation Date'] && value['Lead Creation Date'] !== null && value['Lead Creation Date'] !== undefined ? moment(value['Lead Creation Date']).format('DD-MM-YYYY') : "",
                    //         'Existing Policy Expiry Date': value && value['Existing Policy Expiry Date'] && value['Existing Policy Expiry Date'] !== null && value['Existing Policy Expiry Date'] !== undefined ? moment(value['Existing Policy Expiry Date']).format('DD-MM-YYYY') : "",
                    //         'RFQ Date': value && value['RFQ Date'] && value['RFQ Date'] !== null && value['RFQ Date'] !== undefined ? moment(value['RFQ Date']).format('DD-MM-YYYY') : "",
                    //         'Last Modified Date': value ? moment(value['Last Modified Date']).format('DD-MM-YYYY') : "",
                    //     };
                    // });
                    setReports(result);
                } else {
                    setlead([]);
                    setReports([]);
                    window.alert('No Data available');
                }

            } catch (error) {
                console.error('Error fetching tasks:', error);
                // Handle error here, such as displaying an error message to the user
            }
        }
        // New Change : added a new report (underwriter_report) in the task activity report 12 Nov 2024
        else if (state.apiname === 'underwriter_report') {
            let result = await SparkApi.fetchTaksByOwnerAll(filterDate, moment(fromDate).format("DD-MM-YYYY"), moment(toDate).format("DD-MM-YYYY"), option?.value, state.apiname)
            try {
                if (result && result.length > 0) {
                    setReports(result);
                    // settempdata(result);
                    const headers = Object.keys(result[0])?.map(key => ({ prop: key, title: key }));
                    setHeaders(headers);
                } else {
                    setReports([]);
                    // settempdata([]);
                    setHeaders([]);
                    window.alert('No Data available');
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        }
        else {
            try {
                let result = await SparkApi.fetchTaksByOwnerAll(
                    filterDate,
                    moment(fromDate).format("DD-MM-YYYY"),
                    moment(toDate).format("DD-MM-YYYY"),
                    option?.value,
                    state.apiname
                );
                //console.log('result activity', result)

                if (result && result.length > 0) {

                    setReports(result);
                    setleadActivityReport(result);
                    const headers = Object.keys(result[0])
                        .filter(key => key !== 'managerid' && key !== 'ownerid' && key !== 'Products')
                        .map(key => ({ prop: key, title: key }));
                    // console.log('headers', headers)
                    setHeaders(headers);
                } else {
                    setleadActivityReport([]);
                    setReports([]);
                    window.alert('No Data available');
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
                // Handle error here, such as displaying an error message to the user
            }
        }

    };

    const handleUsers = async (event) => {

        setoption(event)
    }

    useEffect(() => {
        fetchAllUsers()

    }, []);

    //console.log('reports',reports)
    const ReportDataCSV = {
        data: reports,
        // Fixed the report name issue
        filename: state.apiname || "Report",
        delimiter: ',',
        headers: tempLabels
    }

    const [expandedRow, setExpandedRow] = useState(null);

    const toggleRowDetails = (rowId) => {
        // console.log('rowid', rowId)
        setExpandedRow(expandedRow === rowId ? null : rowId);
    };

    //console.log('leads', lead)

    return (
        <Container fluid>
            <Row>
                {/*New Change : Added the breadcrumb to navigate on report list page */}
                <Col lg={12} className="px-3 my-3">
                    <Link className="nav-link my-3" to="/taskreports">
                        Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Reports</div>
                    </Link>
                </Col>

                <Col lg={12} className="px-3 my-3">
                    <Row>
                        {/* New Change : Added new filter according to last modify date */}
                        <Col xs={12} sm={6} lg={3}>
                            <Form.Label htmlFor="fromDate">Filter Date</Form.Label>
                            <Form.Select
                                id="filterDate"
                                value={filterDate}
                                onChange={(e) => setFilterDate(e.target.value)}
                                placeholder="filterDate"
                            >
                                {/* <option value={""}>--Select Option--</option> */}
                                <option value={""}>Created Date</option>
                                <option value={"lastModify"}>Last Modified Date</option>
                            </Form.Select>
                        </Col>
                        <Col xs={12} sm={6} lg={3}>
                            <Form.Label htmlFor="fromDate">From Date</Form.Label>
                            <Form.Control
                                id="fromDate"
                                type="date"
                                value={fromDate}
                                onChange={(e) => setFromDate(e.target.value)}
                                placeholder="From Date"
                            />
                        </Col>
                        <Col xs={12} sm={6} lg={3}>
                            <Form.Label htmlFor="toDate">To Date</Form.Label>
                            <Form.Control
                                id="toDate"
                                type="date"
                                value={toDate}
                                // New Change :  added validation disabled this filed until user select from date 12 NOV 2024
                                disabled={!fromDate}
                                onChange={(e) => setToDate(e.target.value)}
                                placeholder="To Date"
                            />
                        </Col>
                        {/* {state.apiname !== 'meetingreport' && (
                        <Col xs={12} sm={6} lg={3}>
                            <Form.Label htmlFor="status">Status</Form.Label>
                            <Form.Select
                                id="status"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                placeholder="Status"
                            >
                                <option value="">Select Status</option>
                                <option value="Not Started">Not Started</option>
                                <option value="In Progress">In Progress</option>
                                <option value="Completed">Completed</option>
                                <option value="Waiting on someone else">Waiting on someone else</option>
                                <option value="Deferred">Deferred</option>
                            </Form.Select>
                        </Col>)} */}
                        <Col xs={12} sm={6} lg={3}>
                            <Form.Group className="mx-3" controlId="formBasicOwner">
                                <Form.Label className="form-view-label" htmlFor="formBasicOwner" >
                                    Manager Name
                                </Form.Label>
                                <Select
                                    name="ownerid"
                                    value={option}
                                    className="task-select"
                                    onChange={handleUsers}
                                    options={optionUsers}
                                    getOptionValue={(option) => option.value}
                                    isClearable

                                // isMulti
                                />



                            </Form.Group>
                        </Col>

                        <Col xs={12} sm={6} lg={3} style={{ marginTop: '28px' }}>
                            {/*  New Change :  added validation disabled this button until user select date 12 NOV 2024 */}
                            <Button onClick={fetchAllData} disabled={fromDate && !toDate}>Run Report</Button>

                            <Button variant="warning" onClick={() => csvDownload(ReportDataCSV)} className="mx-2">Download CSV</Button>
                            {/* New Change :  added new Button to refresh the data and filters  12 Nov 2024*/}
                            <Button variant="secondary" onClick={() => { fetchAllReportHistory(); setFilterDate(''); setFromDate(''); setToDate(''); setoption([]); }}><i className="fa fa-refresh"></i></Button>

                        </Col>


                    </Row>

                    <Row>
                        {/* Enhanced the report UI used the data-table instead of simple table 8 Oct 2024 @ali*/}
                        <Col lg={12} className="py-3 vw-100 overflow-auto" >
                            {(state.apiname === 'leadactivityreport' && leadActivityReport?.length > 0) &&

                                <DatatableWrapper
                                    body={leadActivityReport}
                                    headers={typeof leadActivityReport[0] === 'object' ? Object.keys(leadActivityReport[0])?.map((header) => { return { "title": header, prop: header } }) : [{}]}
                                    paginationOptionsProps={{
                                        initialState: {
                                            rowsPerPage: 10,
                                            options: [5, 10, 15, 20]
                                        }
                                    }}>
                                    <Table striped className="data-table" responsive="sm">
                                        <TableHeader />
                                        <TableBody />
                                    </Table>
                                    <Pagination />
                                </DatatableWrapper>

                            }
                        </Col>

                        {/* Enhanced the report UI used the data-table instead of simple table 8 Oct 2024 @ali*/}
                        <Col lg={12} className="vw-100 overflow-auto" >
                            {(state.apiname === 'leadsummaryreport' && lead?.length > 0) &&

                                <DatatableWrapper
                                    body={lead}
                                    headers={typeof lead[0] === 'object' ? Object.keys(lead[0])?.map((header) => { return { "title": header, prop: header } }) : [{}]}
                                    paginationOptionsProps={{
                                        initialState: {
                                            rowsPerPage: 10,
                                            options: [5, 10, 15, 20]
                                        }
                                    }}>
                                    <Table striped className="data-table" responsive="sm">
                                        <TableHeader />
                                        <TableBody />
                                    </Table>
                                    <Pagination />
                                </DatatableWrapper>
                                // :
                                // <Stack className="align-items-center justify-content-center"><h3>No data found for this report</h3></Stack>
                            }
                        </Col>



                        <Col lg={12} className="vw-100 overflow-auto" >
                            {reports.length > 0 && state.apiname === 'meetingreport' &&
                                <DatatableWrapper body={reports} headers={headers} paginationOptionsProps={{
                                    initialState: {
                                        rowsPerPage: 10,
                                        options: [5, 10, 15, 20]
                                    }
                                }}>
                                    <Row className="mb-4">
                                        <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                                            {/* <Filter /> */}
                                        </Col>
                                        <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-start align-items-start">
                                            {/* <PaginationOptions labels={labels} /> */}
                                        </Col>
                                    </Row>
                                    <Table striped className="data-table" responsive="sm">
                                        <TableHeader />
                                        <TableBody />
                                    </Table>
                                    <Pagination />
                                </DatatableWrapper>
                            }
                        </Col>
                        {/* New Change : added a new report (underwriter_report) in the task activity report 12 Nov 2024 */}

                        <Col lg={12} className="vw-100 overflow-auto">
                            {reports.length > 0 && state.apiname === 'underwriter_report' &&
                                <DatatableWrapper body={reports} headers={headers} paginationOptionsProps={{
                                    initialState: {
                                        rowsPerPage: 10,
                                        options: [5, 10, 15, 20]
                                    }
                                }}>
                                    <Row className="mb-4">
                                        <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                                            {/* <Filter /> */}
                                        </Col>
                                        <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-start align-items-start">
                                            {/* <PaginationOptions labels={labels} /> */}
                                        </Col>
                                    </Row>
                                    <Table striped className="data-table" responsive="sm">
                                        <TableHeader />
                                        <TableBody />
                                    </Table>
                                    <Pagination />
                                </DatatableWrapper>
                            }
                        </Col>
                    </Row>

                </Col>
            </Row>
        </Container>
    );
}

export default ViewTaskReports;
