import React, { useEffect, useState } from 'react'
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import * as constants from "../constants/CONSTANT";
export default function UnderwriterBulkChangeModel({ UWModalShow, setUWModalShow, selectedIds,underwriters, rows, showCheckBox, setShowCheckBox, changedRows, setChangedRows, userInfo, leadId }) {

    const [UWModalState, setUWModalState] = useState({});

    useEffect(()=>{
        console.log("UWModalState",UWModalState);

        return ()=>{
            console.log("END UWModalState",UWModalState);
        }
    },[UWModalState])
    //for set the states of the modal
  const handleUWModalStateChange = (e) => {
    const fieldName = e?.target?.name;
    const value = (e?.target?.name == "converted") ? e?.target?.checked : e?.target?.value;

    setUWModalState({ ...UWModalState, [fieldName]: value });
  }
    const ChangeUW = (e) => {
        e.preventDefault();
        // console.log("SELECTED ID", selectedIds);
        // console.log("SELECTED ID, product_quote",selectedIds.map((product_quote)=>{ return {product_quote}}));
        // console.log("previous changed",changedRows);
        setChangedRows([...changedRows,...selectedIds.map((product_quote)=>{ return {product_quote}})]);
        // console.log("After Changed add",changedRows);

        // console.log("Model states ", UWModalState);
        rows.forEach(row => {
            if(selectedIds.includes(row.product_quote)){
                for(let key in UWModalState){
                    if(row?.hasOwnProperty(key)) row[key] = UWModalState[key];
                }
            } 
        });
        setUWModalShow(false);
        setUWModalState({});
        setShowCheckBox(false)
    }
    return (
        <>

            {/* Added UWModal for Bulk Change 25 Sept 2024 @ali */}
            {
                UWModalShow && <Modal show={UWModalShow} onHide={() => {setUWModalShow(false);setUWModalState({});}} >
                    <Form
                        onSubmit={ChangeUW}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Change the information of underwriter
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="gap-2">
                                <Col lg={12}>
                                    <Form.Group>
                                        <Form.Label htmlFor="Under Writing Status" >Under Writing Status</Form.Label>
                                        <Form.Select
                                            name="under_writing_status"
                                            // required={fieldValidations["Under Writing Status"]}
                                            value={UWModalState["under_writing_status"] && UWModalState["under_writing_status"]}
                                            onChange={handleUWModalStateChange}
                                            // Add disabled functionality as per client's instructions 19 DEC 2024
                                            disabled={
                                                (userInfo.userrole === 'HEAD_SALES' || userInfo.userrole === 'ADMIN_SALES' || userInfo.userrole === 'RELATIONSHIP_MANAGER')
                                            }
                                        >
                                            <option value={""}>--Select--</option>
                                            {
                                                constants.UNDER_WRITER_STATUS.map((status) => (
                                                    <option key={status.value} value={status.value}>
                                                        {status.value}
                                                    </option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col lg={12}>
                                    <Form.Group>
                                        <Form.Label htmlFor="Under Writer Manager" >Under Writer Manager</Form.Label>
                                        <Form.Select
                                            name="underwriter_manager_id"
                                            // required={fieldValidations["Under Writer Manager"]}
                                            value={UWModalState["underwriter_manager_id"] && UWModalState["underwriter_manager_id"]}
                                            // onChange={(e) => { setUnderwriterManager(e.target.value) }}
                                            onChange={handleUWModalStateChange}
                                        >
                                            <option value={""}>--Select--</option>
                                            {underwriters?.map((underwriter, index) => <option value={underwriter?.id || ""} key={index}>{underwriter.username}</option>)}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                                <Col lg={5}>
                                    <Form.Group>
                                        <Form.Label htmlFor="Remark" >Remark</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            row={0}
                                            style={{ height: "33px" }}
                                            name="remark"
                                            // required={(UWModalState['Under Writing Status'] === 'Data Insufficient to Quote' || UWModalState['Under Writing Status'] === 'Quote Sent to RM')}
                                            value={UWModalState["remark"]}
                                            // Add disabled functionality as per client's instructions 19 DEC 2024
                                            disabled={
                                                (userInfo.userrole === 'HEAD_SALES' || userInfo.userrole === 'ADMIN_SALES' || userInfo.userrole === 'RELATIONSHIP_MANAGER')
                                            }
                                            onChange={handleUWModalStateChange}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label htmlFor="Policy Expiry Dater" >Policy Expiry Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="policy_expiry_date"
                                            // required={fieldValidations["Policy Expiry Date"]}
                                            value={UWModalState["policy_expiry_date"]}
                                            onChange={handleUWModalStateChange}
                                        // value={row["Policy Expiry Date"]}
                                        // onChange={(e) => { handleChangeRowsDemo(e.target.value, row["Product Quote"], "Policy Expiry Date") }}
                                        // disabled={
                                        //   (lead.id && (userInfo.userrole === 'HEAD_SALES' || userInfo.userrole === 'ADMIN_SALES' || userInfo.userrole === 'RELATIONSHIP_MANAGER'))
                                        // }
                                        />
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group>
                                        <Form.Label htmlFor="Risk Inspection" >Risk Inspection</Form.Label>
                                        <Form.Select
                                            name="risk_inspection"
                                            // required={fieldValidations["Risk Inspection"]}
                                            value={UWModalState["risk_inspection"]}
                                            onChange={handleUWModalStateChange}
                                            // Add disabled functionality as per client's instructions 19 DEC 2024
                                            disabled={
                                                (userInfo.userrole === 'HEAD_SALES' || userInfo.userrole === 'ADMIN_SALES' || userInfo.userrole === 'RELATIONSHIP_MANAGER')
                                            }
                                        >
                                            <option value={""}>--Select--</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group>
                                        <Form.Label htmlFor="Converted" >Converted</Form.Label>
                                        <Form.Check
                                            type="checkbox"
                                            name="converted"
                                            // required={fieldValidations["Converted"]}
                                            checked={UWModalState["converted"]}
                                            onChange={handleUWModalStateChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={ChangeUW}>
                                Save
                            </Button>
                            <Button variant="light" onClick={() => {setUWModalState({});setUWModalShow(false);}}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            }
        </>
    )
}